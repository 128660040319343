<template>
  <div class="userinfo_main">
    <div class="userinfo_box">
      <div class="userinfo_avatar">
        <img :src="userInfo.imgUrl" />
      </div>
      <div class="userinformation">
        <h1>{{ userInfo.nickname }}</h1>
        <span>{{ userInfo.job }}</span>
      </div>
    </div>
    <div class="userinfo_menu">
      <router-link :to="{ name: 'user', params: { tab: 'me' } }"
        >个人主页</router-link
      >
      <router-link :to="{ name: 'user', params: { tab: 'user' } }"
        >编辑资料</router-link
      >
      <a @click="handleLogout">退出登录</a>
      <!-- 退出登录 ------------------------->
      <div class="loginout-content" v-if="showModal">
        <p @click="confirm">确定</p>
        <p @click="cancel">取消</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useUserEffect } from '@/store/modules/useUserEffect'
import { useRouter } from 'vue-router'
import { ref, defineEmits } from 'vue'

const { userInfo } = useUserEffect()
const router = useRouter()
const showModal = ref(false)
const emit = defineEmits(['cancel'])

// 退出登录-----------------------------
const handleLogout = () => {
  showModal.value = true
}
// 确认
const confirm = () => {
  localStorage.removeItem('isLogin')
  router.push('/')
  window.location.reload()
  showModal.value = false
}
// 取消
const cancel = () => {
  showModal.value = false
  emit('cancel')
}
</script>

<style lang="scss">
  .userinfo_main {
    position: fixed;
    z-index: 9999;
    top: 0.6rem;
    right: 0.2rem;
    min-width: 1.88rem;
    max-width: 2.88rem;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.08);
    border-radius: 0.1rem;
  }
  .userinfo_box {
    display: flex;
    align-items: center;
    margin: 0.2rem 0 0 0.2rem;
  }
  .userinfo_avatar {
    width: 0.42rem;
    height: 0.42rem;
    border-radius: 0.5rem;
    overflow: hidden;
    background: rgb(219, 219, 219);
  }
  .userinfo_avatar > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* 等比缩放并裁剪 */
  }
  .userinformation {
    flex: 1;
    margin-left: 0.1rem;
    line-height: 0.2rem;
  }
  .userinformation > h1 {
    margin: 0 .2rem 0 0;
    font-weight: bold;
    font-size: 0.16rem;
    color: #222222;
  }
  .userinformation > span {
    font-size: 0.12rem;
    color: #aaaaaa;
  }
  .userinfo_menu {
    display: flex;
    flex-wrap: wrap;
    padding: 0.14rem 0 0.1rem 0.2rem;
  }
  .userinfo_menu a {
    height: 0.46rem;
    line-height: 0.46rem;
    font-size: 0.14rem;
    color: #222222;
    width: 100%;
  }
  .userinfo_menu a:hover {
    color: #11c2fb;
    cursor: pointer;
    background: linear-gradient(270deg, #e7f9ff 0%, #ffffff 100%);
  }
  .loginout-content {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    background: #ffffff;
    height: 0.46rem;
  }
  .loginout-content > p {
    font-size: 0.14rem;
    text-align: center;
    cursor: pointer;
    color: #999;
  }
  .loginout-content > p:hover {
    color: #11c2fb;
  }

</style>
