<template>
    <!-- 组件大图详情 -->
    <div class="widget_large_mask" v-show="showWidget" @click="hideWidget">
    <WidgetPopup
      :imageData="currentImageData"
      :similarData="similarWidget"
      v-show="showWidget"
      @similarWidget="showImage"
      @hide="hideWidget"/>
  </div>

<div class="topsearchbox" @click.stop>
    <div class="historybox">
      <h1>搜索历史</h1>
      <div class="clearhistory"
      @click="clearHistory"
      v-if="searchHistory.length > 0">
        <img src="../assets/images/icon_search_qk.svg">
      </div>
      <div class="historyitembox">
        <div class="historyitem" v-if="searchHistory.length > 0">
            <span v-for="(item, index) in searchHistory" :key="index" @click="handleSearch(item)">{{ item }}</span>
        </div>
        <div class="histor_nul" v-else>
            <img src="../assets/images/icon_histor_null.svg">
            <p>暂无历史记录</p>
        </div>
      </div>
    </div>

    <!-- 推荐搜索 -->
    <div v-if="titleArray.length > 1" class="recommendmain">
      <div class="recommend_titel">
        <h1>猜你想搜</h1>
      </div>
      <div class="recommend_box">
        <div class="recommend_item">
          <span @click="handleTitle(title)" v-for="(title, index) in titleArray" :key="index">{{ title }}</span>
        </div>
      </div>
      </div>
    <!-- 搜索推荐 -->
    <div class="recommendmain">
      <div class="recommend_titel">
        <h1>快捷导航</h1>
      </div>
        <div class="recommendbox">
          <div
            class="listitem_img"
            v-for="item in hoemAd.slice(0, 4)"
            :key="item._id"
            >
            <a
              :href="item.link"
              target="_blank"
              >
              <img :src="item.image">
            </a>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import { inject, getCurrentInstance, ref, provide, onMounted } from 'vue'
import { get, post } from '../utils/request'
import WidgetPopup from '../components/WidgetPopup.vue'
import { useUserEffect } from '@/store/modules/useUserEffect'
import { useRouter } from 'vue-router'

const router = useRouter() // 使用 useRouter 获取路由实例
const { userInfo } = useUserEffect()
const { emit } = getCurrentInstance()
const searchHistory = inject('searchHistory', []) // 接收父组件搜索历史记录
const currentImageData = ref(null) // 弹窗大图
const showWidget = ref(false) // 组件弹窗
const similarWidget = ref([]) // 相似组件数据
const isCollected = ref(false) // 收藏状态
provide('isCollected', isCollected) // 共享收藏状态给子组件
const hoemAd = ref([]) // 首页广告
// const baseURL = 'https://localhost:3002' // 本地开发
const baseURL = 'https://admin.iuids.com:3002' // 线上

// 登录状态
const isLoggedIn = () => {
  return localStorage.getItem('isLogin') === 'true'
}

// 首页广告
const getHomeAd = async () => {
  const result = await get('/api/homead/ad')
  if (result?.errno === 0 && result?.data?.length) {
    hoemAd.value = result.data
  }
}
onMounted(async () => {
  getHomeAd()
  // 过滤出长度小于 5 的搜索记录
  const filteredHistory = searchHistory.value.filter(item => item.length < 5)

  if (filteredHistory.length > 0) {
    const randomIndex = Math.floor(Math.random() * filteredHistory.length)
    const randomSearchTerm = filteredHistory[randomIndex] // 随机选择一条内容
    await generateTitle(randomSearchTerm) // 调用 generateTitle 函数
  }
})

// 生成标题
const rawTitle = ref('') // 原始标题
const titleArray = ref([]) // 提取的标题数组
const generateTitle = async (searchTerm) => {
  if (!isLoggedIn()) {
    return
  }
  try {
    const response = await fetch(`${baseURL}/api/qwen/title`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ searchTerm })
    })
    if (response?.status === 200) {
      const fullContent = await response.json()
      rawTitle.value = fullContent.data

      // 使用正则表达式提取换行符分隔的内容
      const matches = rawTitle.value.match(/\d+\.\s(.*?)(?=\n|$)/g)
      if (matches) {
        titleArray.value = matches.map(match => match.replace(/\d+\.\s/, '')) // 去除序号
      }
    }
  } catch (e) {
    console.error('服务器异常', e)
  }
}

// 【猜你想搜】点击标题搜索
const handleTitle = (title) => {
  const newRoute = {
    name: 'search',
    query: { type: 'ai', q: title }
  }
  router.push(newRoute)
}

// 清空搜索历史记录
const clearHistory = () => {
  searchHistory.value = [] // 清空搜索历史记录
  // ctx.searchHistory.length = 0
  emit('clear-history', [])
}
// 点击历史记录传递给父组件
const handleSearch = (term) => {
  emit('search', term)
}
// 获取单个组件逻辑
const showImage = async (id) => {
  if (isLoggedIn()) {
    const userId = userInfo._id
    isCollected.value = await checkIfCollected(userId, id)
  }
  try {
    const result = await get(`/api/widget/${id}`)
    if (result?.errno === 0 && result?.data) {
      currentImageData.value = result.data // 更新当前图片数据
      enterWidgetPopup() // 显示图片弹窗
      getWidgetByTags(id) // 按标签查询相似组件
    } else {
      console.error('获取失败:', result?.errmsg || '未知错误')
    }
  } catch (error) {
    console.error('获取失败:', error.message)
  }
}

// 按标签查询相似组件
const getWidgetByTags = async (id) => {
  const tags = currentImageData.value.tags
  const response = await post('/api/widget/search/tags', { tags, id })
  if (response?.errno === 0 && response?.data) {
    similarWidget.value = response.data
  }
}

// 检查当前数据是否已收藏
const checkIfCollected = async (userId, itemId) => {
  try {
    const response = await get(`/api/collect/check/${userId}/${itemId}`)
    if (response?.errno === 0 && response?.data) {
      return true
    }
  } catch (error) {
    console.error('检查是否收藏失败:', error.message)
  }
  return false
}

// 显示图片弹窗
const enterWidgetPopup = () => {
  showWidget.value = true
  if (showWidget.value) {
    document.body.style.overflow = 'hidden'
  } else {
    // 隐藏子组件后恢复滚动
    document.body.style.overflow = 'auto'
  }
}

// 隐藏弹窗
const hideWidget = async () => {
  showWidget.value = false // 子组件点击按钮隐藏后的回调
  document.body.style.overflow = 'auto' // 恢复滚动
}

</script>

<style lang="scss">
@import '../style/searchpopup.scss'
</style>
